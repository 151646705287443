const Summaries = () => {
  const summaries = {
    hisa_summary: {
      summary_id: "hisa_summary",
      title: "High Interest Savings Account",
      summary: "You should open a high interest savings account.",
      read_more_link: "https://eqbank.ca",
      affiliate_partners: [],
    },
    long_term_investing_summary: {
      summary_id: "long_term_investing_summary",
      title: "Long Term Investing",
      summary:
        "Long-term savings accounts are designed to help you save and invest money and earn better returns than your traditional savings accounts. You can use long-term savings to help cover college expenses, retirement, or big purchases like a house.",
      read_more_link: "https://wealthsimple.com",
      affiliate_partners: [
        {
          partner_name: "Wealthsimple",
          partner_link:
            "https://my.wealthsimple.com/app/public/trade-referral-signup?code=PRUCOA",
          partner_message:
            "Modern investing for everyone. Signup with the link below to earn free stocks.",
        },
        {
          partner_name: "Questrade",
          partner_link:
            "https://start.questrade.com/?oaa_promo=495907905448821&s_cid=RAF14_share_link_refer_a_friend_email&utm_medium=share_link&utm_source=refer_a_friend&utm_campaign=RAF14&utm_content=email",
          partner_message:
            "A long-term investing platform, Questrade is a great option for Canadians to start investing. Get up to $250 cash back if you sign up now.",
        },
      ],
    },
    budgeting_summary: {
      summary_id: "budgeting_summary",
      title: "Budgeting",
      summary:
        "A budget is a plan for your money. A budget helps you plan how much money will go where so that there are no surprises. It’s a way to make sure that money doesn’t slip through your fingers without any control over it.",
      read_more_link: "https://mint.com",
      affiliate_partners: [
        {
          partner_name: "Mint",
          partner_link: "https://mint.com",
          partner_message:
            "Mint is one of the most popular budgeting apps. It’s free and easy to use and a great way to get started with budgeting.",
        },
        {
          partner_name: "You Need a Budget",
          partner_link: "https://www.youneedabudget.com/",
          partner_message:
            "YNAB is a great budgeting app that takes a more hands on approach to budgeting.",
        },
      ],
    },
    emergency_fund_summary: {
      summary_id: "emergency_fund_summary",
      title: "Emergency Fund",
      summary:
        "An emergency saving is a reserve of money on hand that can be accessed at any time for any number of reasons. Whether you’re saving up for a new car or just want to avoid credit card debt, it’s important to have some cash on hand in case you need it.",
      read_more_link: "https://mint.com",
      affiliate_partners: [
        {
          partner_name: "EQ Bank",
          partner_link: "https://join.eqbank.ca?code=SUPREET2270",
          partner_message:
            "Get $20 when you sign up with the link and earn a guaranteed 2.30% interest on your savings with EQ Bank.",
        },
        {
          partner_name: "Tangerine",
          partner_link: "https://tangerine.ca",
          partner_message:
            "Earn 4.0% interest for the first 6 months. Limited time offer.",
        },
        {
          partner_name: "TD Canada Trust",
          partner_link: "https://tdcanadatrust.com",
          partner_message:
            "Earn 0.01% interest on your savings when you sign up for a TD Savings Account.",
        },
      ],
    },
  };

  return summaries;
};

export default Summaries;
