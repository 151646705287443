import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setCurrentQuestion } from "../redux/questionSlice";
import Quiz from "./Quiz";

const Home = () => {
  const dispatch = useDispatch();
  // const currentQuestionId = useSelector(
  //   (state) => state.questions.currentQuestionId
  // );
  const navigate = useNavigate();
  const location = useLocation();

  const startQuiz = () => {
    dispatch(setCurrentQuestion("have_budget"));
    navigate(`/question/have_budget`);
  };

  const renderBeginButton = () => {
    return (
      <div className="flex flex-row w-full mx-20 items-center justify-center">
        <button
          onClick={() => startQuiz()}
          className="inline-flex items-center rounded-full border border-transparent bg-pear-dark-green px-6 py-3 text-base font-medium text-white shadow-sm hover:opacity-90"
        >
          Begin now
        </button>
      </div>
    );
  };

  return (
    <>
      {location.pathname === "/" ? (
        renderBeginButton()
      ) : (
        <div>
          <Quiz />
        </div>
      )}
    </>
  );
};

export default Home;
