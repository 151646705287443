import { ArrowSmallLeftIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const exit = () => {
    navigate("/");
  };

  return (
    <div className="sticky top-0 bg-pear-beige">
      <div className="mx-auto px-2">
        {/* <div className="relative flex items-center justify-between h-16"> */}
        <div className="flex flex-1 items-center justify-between h-16">
          {location.pathname !== "/" ? (
            <button onClick={goBack} className="px-5">
              <ArrowSmallLeftIcon className="h-8 w-8" />
            </button>
          ) : null}
          <h1 className="font-bold text-xl mx-auto">Pear</h1>
          {location.pathname !== "/" ? (
            <button onClick={exit} className="px-5">
              <XMarkIcon className="h-8 w-8" />
            </button>
          ) : null}
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Navbar;
