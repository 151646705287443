import { useSelector, useDispatch } from "react-redux";
import Questions from "../questions";
import RadioGroup from "./RadioButtons";
import { useState } from "react";
import { setCurrentQuestion } from "../redux/questionSlice";
import { useParams, useNavigate } from "react-router-dom";

const Quiz = () => {
  const { questionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const questions = Questions();
  const currentQuestion = questions[questionId];

  const submitResponse = (value) => {
    console.log("submitResponse", value);
    const nextRoute = currentQuestion.nextRoute[value];

    if (nextRoute.type == "question") {
      dispatch(setCurrentQuestion(nextRoute.route));
      navigate(`/question/${nextRoute.route}`);
    } else if (nextRoute.type == "summary") {
      navigate(`/summary/${nextRoute.route}`);
    }
  };

  return (
    <div className="w-4/5">
      <div className="text-center text-3xl py-4 font-bold text-black">
        {currentQuestion.prompt}
      </div>
      <div className="text-center w-2/3 mx-auto text-pear-dark-green">
        {currentQuestion.subText}
      </div>
      <div>
        {currentQuestion.responseType === "radio" && (
          <RadioGroup
            questionId={questionId}
            choices={currentQuestion.choices}
            submitResponse={submitResponse}
          />
        )}
        {/* {currentQuestion.responseType === "slider" && renderSliderAction()} */}
        {/* {currentQuestion.responseType === "no_op" && renderNoOpAction()} */}
        {/* {response !== null ? renderSubmitButton() : null} */}
      </div>
    </div>
  );
};

export default Quiz;
