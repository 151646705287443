import Home from "./components/Home";
import Quiz from "./components/Quiz";
import Summary from "./components/Summary";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { init, track } from "@amplitude/analytics-browser";
import { useEffect } from "react";

function App() {
  let location = useLocation();

  useEffect(() => {
    init(process.env.REACT_APP_AMPLITUDE_API_KEY);
  }, []);

  return (
    <div className="h-screen bg-pear-beige top-0 bottom-0">
      <Navbar />
      <div className="flex top-0 h-[calc(100vh-64px)] justify-center items-center ">
        <TransitionGroup component={null}>
          <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={300}
            exit={false}
          >
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="question">
                <Route path=":questionId" element={<Quiz />} />
              </Route>
              <Route path="summary">
                <Route path=":summaryId" element={<Summary />} />
              </Route>
            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
}

export default App;
