import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";

const RadioButtons = (props) => {
  const [selected, setSelected] = useState(null);

  const handleChange = (value) => {
    setSelected(value);
    props.submitResponse(value);
  };

  useEffect(() => {
    setSelected(null);
  }, [props.questionId]);

  useEffect(() => {
    console.log("selected", selected);
  }, [selected]);

  function renderOption(value, label) {
    return (
      <RadioGroup.Option
        key={value}
        value={value}
        className={({ checked, active }) =>
          classNames(
            checked ? "border-transparent" : "border-gray-300",
            active ? "border-pear-green ring-2 ring-pear-green" : "",
            "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
          )
        }
      >
        {({ checked, active }) => (
          <>
            <span className="flex flex-1">
              <span className="flex flex-col">
                <RadioGroup.Label
                  as="span"
                  className="block text-sm font-medium text-gray-900"
                >
                  {label}
                </RadioGroup.Label>
              </span>
            </span>
            <CheckCircleIcon
              className={classNames(
                !checked ? "invisible" : "",
                "h-5 w-5 text-pear-dark-green"
              )}
              aria-hidden="true"
            />
            <span
              className={classNames(
                active ? "border" : "border-2",
                checked ? "border-pear-green" : "border-transparent",
                "pointer-events-none absolute -inset-px rounded-lg"
              )}
              aria-hidden="true"
            />
          </>
        )}
      </RadioGroup.Option>
    );
  }

  return (
    <RadioGroup
      className="mx-auto w-1/2"
      value={selected}
      onChange={handleChange}
    >
      <div
        className={classNames(
          "mt-4 grid grid-cols-1 gap-y-6 sm:gap-x-4",
          props.choices.length == 2 ? `md:grid-cols-2` : `md:grid-cols-3`
        )}
      >
        {props.choices.map((choice) =>
          renderOption(choice.value, choice.label)
        )}
      </div>
    </RadioGroup>
  );
};

export default RadioButtons;
