import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useParams, useNavigate } from "react-router-dom";
import Summaries from "../summaries";
import { logEvent } from "../amplitude";
import { track } from "@amplitude/analytics-browser";

const Summary = () => {
  const { summaryId } = useParams();

  const summaries = Summaries();
  const currentSummary = summaries[summaryId];

  function renderOld() {
    return (
      <div className="w-4/5 text-center">
        <div className="text-center text-3xl py-4 font-bold text-black">
          {currentSummary.title}
        </div>
        <div className="text-center w-2/3 mx-auto text-pear-dark-green">
          {currentSummary.summary}
        </div>
        <a
          href={currentSummary.read_more_link}
          target="_blank"
          className="justify-center w-2/3 mx-auto text-pear-orange flex flex-row"
        >
          <span>Read more</span>
          <span>
            <ArrowTopRightOnSquareIcon className="w-4 h-4" />
          </span>
        </a>
      </div>
    );
  }

  function renderAffiliatePartner(affiliatePartner) {
    return (
      <a
        href={affiliatePartner.partner_link}
        onClick={() =>
          track("referral_link_clicked", {
            partner_name: affiliatePartner.partner_name,
          })
        }
        target="_blank"
      >
        <div className="bg-white rounded-lg shadow p-4 my-4">
          <h2 className="font-semibold font-pear">
            {affiliatePartner.partner_name}
          </h2>
          <div className="bg-white">{affiliatePartner.partner_message}</div>
        </div>
      </a>
    );
  }

  return (
    <div className="flex flow-row w-full h-full">
      <div className="w-1/2 text-center flex flex-col h-full bg-pear-beige-dark">
        <div className="my-auto font-bold text-4xl">{currentSummary.title}</div>
      </div>
      <div className="w-1/2 text-center flex flex-col h-full px-4">
        <div className="my-auto">
          <div>{currentSummary.summary}</div>
          <a
            href={currentSummary.read_more_link}
            target="_blank"
            className="justify-center w-2/3 mx-auto text-pear-orange flex flex-row"
          >
            <span>Read more</span>
            <span>
              <ArrowTopRightOnSquareIcon className="w-4 h-4" />
            </span>
          </a>

          <div className="mt-5">
            Check out the following offers from our partners to get you started
          </div>

          {currentSummary.affiliate_partners.map((affiliatePartner) =>
            renderAffiliatePartner(affiliatePartner)
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
