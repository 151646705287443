const Questions = () => {
  const questions = {
    have_budget: {
      question: "have_budget",
      prompt: "Do you have a budget?",
      subText:
        "A budget is a plan for your money. It’s important to have a budget so that you can make sure you're spending your money wisely and staying on track to meet your financial goals.",
      responseType: "radio",
      choices: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No, help me create one!",
        },
      ],
      nextRoute: {
        false: { type: "summary", route: "budgeting_summary" },
        true: { type: "question", route: "emergency_fund" },
      },
    },
    emergency_fund: {
      question_id: "emergency_fund",
      prompt: "Do you have an emergency fund?",
      subText:
        "An emergency fund is a reserve of money on hand that can be accessed at any time for any number of reasons. Whether you’re saving up for a new car or just want to avoid credit card debt, it’s important to have some cash on hand in case you need it.",
      responseType: "radio",
      choices: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No, help me create one!",
        },
      ],
      nextRoute: {
        true: { type: "question", route: "emergency_fund_amount" },
        false: { type: "summary", route: "emergency_fund_summary" },
      },
    },
    emergency_fund_amount: {
      question_id: "emergency_fund_amount",
      prompt: "Do you have any large expenses in the next 6 months?",
      subText:
        "You should have at least 6 months of expenses in your emergency fund.",
      responseType: "radio",
      choices: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
      nextRoute: {
        true: { type: "summary", route: "hisa_summary" },
        false: { type: "summary", route: "long_term_investing_summary" },
      },
    },
  };

  return questions;
};

export default Questions;
