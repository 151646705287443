import { createSlice } from "@reduxjs/toolkit";

export const questionSlice = createSlice({
  name: "questions",
  initialState: {
    currentQuestionId: null,
  },
  reducers: {
    setCurrentQuestion: (state, action) => {
      state.currentQuestionId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentQuestion } = questionSlice.actions;

export default questionSlice.reducer;
